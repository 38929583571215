import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import WorkLayout from "../../components/work-layout";
import slide1 from '../../../../app/assets/img/work/bandwaggon/main.png';
import slide2 from '../../../../app/assets/img/work/bandwaggon/how-it-works.png';
import slide3 from '../../../../app/assets/img/work/bandwaggon/media-page.png';
import slide4 from '../../../../app/assets/img/work/bandwaggon/activity-page.png';
import slide5 from '../../../../app/assets/img/work/bandwaggon/user-collection.png';

const works = [
    {'title': 'CompanyThread', 'url': '/work/company-thread'},
    {'title': 'Bandwaggon', 'url': '/work/bandwaggon'},
    {'title': 'Scherago', 'url': '/work/scherago'},
];

const slides = [
    slide1,
    slide2,
    slide3,
    slide4,
    slide5,
];

const selected = works[1];
const next = works[2];
const prev = works[0];

const description = `
Bandwaggon portfolio.
My experience working on “crowdearning” social startup for musicians and their fans.
Amazon & PayPal payments integration.
Tools: PHP & Yii, Javascript & Backbone.js
`;

const keywords = ['Portfolio', 'Bandwaggon', 'Web Developer', 'Backbone', 'JavaScript', 'Yii', 'PHP', 'Startup', 'Amazon Payments', 'PayPal'];


const SecondPage = () => (
  <Layout>
      <SEO title="Bandwaggon - Portfolio" description={description} keywords={keywords}/>
      <WorkLayout next={next} prev={prev} title={selected.title} slides={slides}>
          <p>
              I always wanted to build some social web site and if it is joint with music it is even better. With
              Bandwaggon I got a chance to make it up. Facebook and payment services integration were quite tricky, but
              very useful experience.
          </p>

          <div className="about-project">
              <p>{' '}
                  Bandwaggon is a "crowdearning" social startup for musicians and their fans with a great idea behind
                  it:
                  Believing in someone and helping them succeed is one of the coolest experiences around. So, we built a
                  community designed to help artists succeed by allowing fans to invest in their success. When you buy
                  content on Bandwaggon you have an opportunity to earn points and royalties from everyone you share it
                  with, they share it with, and on and on it goes.{' '}
              </p>
          </div>
          <ul className="tools list-arrow-style">
              <li>PHP + MySQL + Yii framework + PHPUnit + Behat</li>
              <li>JavaScript + jQuery + Backbone.js + Jasmine BDD</li>
              <li>HTML + Twitter Bootstrap</li>
              <li>CSS + SASS + Compass</li>
              <li>Facebook & Twitter Integration</li>
              <li>Payments with Amazon & PayPal</li>
          </ul>
      </WorkLayout>
  </Layout>
);

export default SecondPage
